import React from 'react'
import Layout from '../components-en/layout'
import Head from '../components-en/head'
import { Link, useStaticQuery, graphql } from "gatsby"

import campaign from "../images/campaign.svg"

import soru from "../images/soru.svg"
import { RiArrowRightSLine } from 'react-icons/ri';

import Image from "gatsby-image"



import '../styles/grid.scss'
import '../styles/index.scss'


const CampaignPage = () => {
  
  const data = useStaticQuery(graphql`
    query {
        allCampaignsJson {
          edges {
            node {
              name
              category
              discountAmount
              discountType
              image{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              link
            }
          }
          }
    }
  `)

  return(
    <Layout>
      <Head title="Avantajlar" description="Çember’de biriktirdiğin paraları ne için harcamak istersin? Kullanıcılara özel avantajlar için aşağıdaki markalara göz at." />
      <div className="row campaignHero">
       <div className="hero-container">
        <div className="container">
          <div className="row">
         
            <div className="col-6">
              <div className="hero-text">
                <h1>Campaigns <span role="img" aria-label="Emoji"> 💸 </span> </h1>
                <p>Which goal are you realizing with the money you save in Çember? We are here to support your dreams 
                    with our partner brands.  </p>  
                <p>If you couldn't find your favorite brand, get in touch with us!</p>  
                 <h5>
                   <Link className="cta-button" to="/en/contact">Contact us</Link>
                </h5>        
              </div>
            </div>
            <div className="col-6 center">
              <img src={campaign} alt="Campaigns"/> 
            </div>
            
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
        <div className="container campaigns">
          <div className="row section-title">
            <h1>All Campaigns <span role="img" aria-label="Emoji"> ✨ </span> </h1>
          </div>
          <div className="row">
              <ul>
              {data.allCampaignsJson.edges.map((brandCampaign)=>{
                console.log(brandCampaign)
                
                return(
                  <li>
                    <a rel="noreferrer" target="_blank" href={brandCampaign.node.link}>
                    
                    <Image link={brandCampaign.node.link}
                        fluid={brandCampaign.node.image.childImageSharp.fluid}
                        alt={brandCampaign.node.name}
                      />
                      
                    </a>

                    
                    
                  </li>
                
                )
              })}
              </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="col-12">
            <div className="sorukutu">
              <h1>If you have questions, get in touch!</h1>
              <Link to="/en/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Contact us </span> </Link>
              <img src={soru} alt="Ask a question"/>
            </div>
          </div>
        </div>
    </div>

    </Layout>
  )
  
}


export default CampaignPage